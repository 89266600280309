<template>
  <Image
    :src="languageFlag"
    :width="width ? width : -1"
    :height="height ? height : -1"
    :radius="`circle`"
    :alt="`${$t('language-info')} : ${$t('language')} - ${language}`"
  />
</template>

<script lang="ts">
export default {
  name: 'global-language',
  props: {
    language: {
      type: String,
      default () {
        return ''
      }
    },
    width: {
      type: Number,
      default () {
        return 0
      }
    },
    height: {
      type: Number,
      default () {
        return 0
      }
    }
  },
  setup (props) {

    const languageFlag: any = ref(globals.getImage(`/flag/${props.language ? props.language.toLowerCase() : 'us'}.webp`))

    // 메모리 비우기
    function restore () {
      languageFlag.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    return {
      languageFlag
    }
  }
}
</script>